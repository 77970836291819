import './Navigation.css';

function Navigation({isLoggedIn, userData, currentView, setCurrentView}) {
  let iconList, navList, viewList;

  if (isLoggedIn) {
    iconList = ['home', 'celebration', 'person', 'settings'];
    navList = [userData.name, 'Etkinlikler', 'Kişiler', 'Ayarlar'];
    viewList = ['UserView', 'EventsView', 'PeopleView', 'SettingsView'];
  } else {
    iconList = ['waving_hand', 'celebration', 'person', 'login'];
    navList = ['Merhaba!', 'Etkinlikler', 'Kişiler', 'Giriş'];
    viewList = ['IntroView', 'EventsView', 'PeopleView', 'LoginView'];
  }

  return (
    <div className='navigation'>
      <ul>
        {navList.map((navItem, i) => 
          <li className={viewList[i] === currentView ? 'active':'passive'} 
            key={i}>
              <a id={viewList[i] + 'Button'} href='' onClick={(e) => {
              e.preventDefault();
              setCurrentView(viewList[i]);
            }}>
            <span className="material-symbols-outlined">{iconList[i]}</span>
            <p>{navItem}</p>
          </a></li>)
        }
      </ul>
    </div>
  );
}

export default Navigation;
