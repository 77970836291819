function checkResponse(res) {
  switch (res.code){
    case 1:
      return;
      break;
    case 2:
      alert('Giriş bilgilerinde hata var.');
      break;
    case 3:
      alert('Bu email ile kullanıcı bulunamadı.');
      break;
    case 4:
      alert('Şifre hatalı.');
      break;
    case 5:
      alert('Giriş bilgileri eksik.');
      break;
    case 6:
      alert('Email girdilerde bulunamadı.');
      break;
    case 7:
      alert('Şifre girdilerde bulunamadı.');
      break;
    case 8:
      alert('İstenilen kullanıcı bulunamadı.');
      break;
    case 9:
      alert('Girilen email kullanımda.');
      break;
    case 10:
      alert('Önce giriş yapman gerekiyor.');
      break;
    default:
      alert('Bilinmeyen hata yakalandı.');
      break;
  }
}

export {checkResponse};

