import {useState, useEffect} from 'react';
import {s3Prefix} from './env';
import {date2DatetimeString} from './helpers';
import Person from './Person';
import {InputButton} from './Inputs';
import MyMap from './MyMap';
import {cancelEvent, getPerson, getPeople, leaveEvent, joinEvent, 
  getEmbedding, declineInvitation} from './requests';
import {getCharacteristicScore, getHarmonyScore, HarmonyTable} from './harmony.js';
import './Event.css';

function Event({eventData, userData, setUserData, setEventsData, isInvitedEvent}) {
  const [hasMap, setHasMap] = useState(false);
  const [ownersData, setOwnersData] = useState(null);
  const [applicantsData, setApplicantsData] = useState([]);
  const [attendersData, setAttendersData] = useState([]);
  const [embedding, setEmbedding] = useState(null);

  function handleCancelEventClick(e) {
    e.preventDefault();
    cancelEvent(userData.pid, userData.password, eventData.eid);
    setEventsData(prevEventsData => 
      prevEventsData.filter(prevEventData => prevEventData.eid !== eventData.eid));
  }

  function handleDeclineEventClick(e) {
    e.preventDefault();
    declineInvitation(userData.pid, userData.password, eventData.eid);
    setEventsData(prevEventsData => 
      prevEventsData.filter(prevEventData => prevEventData.eid !== eventData.eid));
    setUserData(prevUserData => {return {...prevUserData, 
      invitedevents: prevUserData.invitedevents.filter(eid => eid != eventData.eid) }});
  }

  function handleShareEventClick(e) {
    e.preventDefault();
    // ....
  }

  function handleShowOnMapClick(e) {
    e.preventDefault();
    if (userData.pid != 0) {
      setHasMap(prevHasMap => !prevHasMap);
    } else {
      alert('Lütfen önce giriş yapın.');
    }
  }

  useEffect(() => {
    if (hasMap) {
      new MyMap('eventMap-' + eventData.eid, eventData.lat, eventData.lng);
    }
  }, [hasMap]);

  function handleShowOwnerClick (e) {
    e.preventDefault();
    if (ownersData) {
      setOwnersData(null);
    } else {
      getPerson(userData.pid, userData.password, eventData.ownerid)
        .then(resObj => setOwnersData(resObj))
        .catch(e => alert(e));
    }
  }

  function handleShowApplicantsClick(e) {
    e.preventDefault();
    if (applicantsData.length > 0) {
      setApplicantsData([]);
    } else {
      getPeople(userData.pid, userData.password, eventData.applicants)
        .then(people => setApplicantsData(people))
        .catch(e => alert(e));
    }
  }

  function handleShowAttendersClick(e) {
    e.preventDefault();
    if (attendersData.length > 0) {
      setAttendersData([]);
    } else {
      getPeople(userData.pid, userData.password, eventData.attenders)
        .then(people => setAttendersData(people))
        .catch(e => alert(e));
    }
  }

  async function handleShowHarmonyClick(e) {
    e.preventDefault();
    if (userData.pid == 0) {
      alert('Lütfen önce giriş yapın.');
      return;
    }
    if (embedding) {
      setEmbedding(null);
    } else {
      const embedding = 
        await getEmbedding(userData.pid, userData.password, 'event', eventData.eid);
      setEmbedding(eval(embedding));
    }
  }

  function handleLeaveEventClick(e) {
    e.preventDefault();
    leaveEvent(userData.pid, userData.password, eventData.eid);
    setEventsData(prevEventsData => {
      const eventsData = [];
      for (let prevEventData of prevEventsData) {
        if (prevEventData.eid === eventData.eid) {
          prevEventData.applicants = prevEventData.applicants.filter(
            applicantPID => applicantPID !== userData.pid);
          prevEventData.attenders = prevEventData.attenders.filter(
            attenderPID => attenderPID !== userData.pid);
        }
        eventsData.push(prevEventData);
      }
      return eventsData;
    });
    setAttendersData(prevAttendersData => 
      prevAttendersData.filter(prevAttenderData => 
        prevAttenderData.pid !== userData.pid
      )
    );
    setApplicantsData(prevApplicantsData => 
      prevApplicantsData.filter(prevApplicantData => 
        prevApplicantData.pid !== userData.pid
      )
    );
  }

  function handleJoinEventClick(e) {
    e.preventDefault();
    if (userData.pid == 0) {
      alert('Lütfen önce giriş yapın.');
      return;
    }
    joinEvent(userData.pid, userData.password, eventData.eid);
    setEventsData(prevEventsData => {
      const eventsData = [];
      for (let prevEventData of prevEventsData) {
        if (prevEventData.eid === eventData.eid) {
          prevEventData.applicants.push(userData.pid);
        }
        eventsData.push(prevEventData);
      }
      return eventsData;
    });
    if (applicantsData.length > 0) {
      setApplicantsData(prevApplicantsData => [...prevApplicantsData, userData]);
    }
  }

  return (
    <>
      <div className='event'>
        <img loading='lazy' alt='event' src={s3Prefix + eventData.urls[0]} />
        <h3>{eventData.title}</h3>
        <p>{eventData.type} etkinliği</p>
        <p>{date2DatetimeString(new Date(parseInt(eventData.eventtime)))}</p>
        <p>{eventData.maxattendeecount} kişilik etkinlik.</p>
        <p>{eventData.applicants.length} kişi bekliyor.</p>
        <p>{eventData.attenders.length} kişi katılıyor.</p>
        <br />
        <p>{eventData.description}</p>
        <br />
        {userData.pid === eventData.ownerid && 
          <InputButton id={'cancelEvent-' + eventData.eid + '-Button'} 
            onClick={handleCancelEventClick} value='İptal' />
        }
        <InputButton id={'showOwner-' + eventData.eid + '-Button'}
          onClick={handleShowOwnerClick} value='Kurucu' />
        <InputButton id={'showApplicants-' + eventData.eid + '-Button'}
          onClick={handleShowApplicantsClick} value='Bekleyenler' />
        <InputButton id={'showAttenders-' + eventData.eid + '-Button'}
          onClick={handleShowAttendersClick} value='Katılanlar' />
        <InputButton id={'showOnMap-' + eventData.eid + '-Button'}
          onClick={handleShowOnMapClick} value='Harita' />
        <InputButton id={'showHarmony-' + eventData.eid + '-Button'}
          onClick={handleShowHarmonyClick} value='Uyum' />
        {eventData.applicants.includes(userData.pid) || eventData.attenders.includes(userData.pid) ?
          <InputButton id={'leaveEvent' + eventData.eid + '-Button'}
            onClick={handleLeaveEventClick} value='Katılmaktan Vazgeç' />:
          <InputButton id={'joinEvent' + eventData.eid + '-Button'}
            onClick={handleJoinEventClick} value='Katıl' />
        }
        {isInvitedEvent && 
          <InputButton id={'declineEvent' + eventData.eid + '-Button'}
            onClick={handleDeclineEventClick} value='Daveti Reddet' />
        }
        {ownersData && 
            <>
              <h3 className='ownerh3'>Kurucu</h3>
              <Person personData={ownersData} userData={userData} />
            </>
        }
        {applicantsData.length > 0 && 
            <div className='applicants'>
              <h3>Bekleyenler</h3>
              {
                applicantsData.map((applicantData, i) => {
                  return userData.pid === eventData.ownerid ?
                    <Person key={i} personData={applicantData} userData={userData} 
                      eventData={eventData} isApplicant={true} 
                      setApplicantsData={setApplicantsData} 
                      setAttendersData={setAttendersData} 
                      setEventsData={setEventsData} />:
                    <Person key={i} eventData={eventData} 
                      personData={applicantData} userData={userData} />
                  }
                )
              }
            </div>
        }
        {attendersData.length > 0 && 
            <div className='attenders'>
              <h3>Katılanlar</h3>
              {
                attendersData.map((attenderData, i) => {
                  return userData.pid == eventData.ownerid ?
                    <Person key={i} personData={attenderData} userData={userData} 
                      eventData={eventData} isAttender={true}
                      setAttendersData={setAttendersData}
                      setEventsData={setEventsData} />:
                    <Person key={i} eventData={eventData} 
                      personData={attenderData} userData={userData} />
                })
              }
            </div>
        }
        {hasMap && <div id={'eventMap-' + eventData.eid}></div>}
        {embedding &&
          <div className='harmonyAnalysis'>
            <h3>Uyum Analizi</h3>
            <p><b>Karakteristik Puanı:</b> {getCharacteristicScore(embedding)}</p>
            <p><b>Uyum Puanı:</b> {getHarmonyScore(userData.embedding, embedding)}</p>
            <HarmonyTable userEmbedding={userData.embedding} 
              otherEmbedding={embedding} />
          </div>
        }
      </div>
      <hr />
    </>
  );
}

export default Event;
