import LoginForm from './LoginForm';

function LoginView({setIsLoggedIn, setCurrentView, setUserData, setAnswers, coords, 
  setUserAnswers}) {
  return (
    <LoginForm 
      setIsLoggedIn={setIsLoggedIn}
      setCurrentView={setCurrentView} 
      setUserData={setUserData} 
      setAnswers={setAnswers}
      coords={coords}
      setUserAnswers={setUserAnswers}
    />
  );
}

export default LoginView;
