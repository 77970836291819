function Terms() {
  return (
    <>
      <h1>Terms and Conditions</h1>
      <p>
        By accessing or using our website, you agree to comply with and be bound
        by the following terms and conditions. Please read these terms carefully
        before using our services. If you do not agree to any part of these
        terms you should not use our website.
        <br />
        Eligibility: To register and use our website, users must be at least 18
        years old. People under the age of 18 are not allowed to use this site.
        <br />
        User Conduct: Users must not post or transmit any content that is
        unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar,
        obscene, sexually suggestive or otherwise objectionable. Users must not
        engage in any conduct that constitutes harassment or discrimination
        based on race, ethnicity, gender, sexual orientation, religion or other
        protected characteristics.
        <br />
        Content Ownership: By submitting content, users grant bsocial.today a
        worldwide, non-exclusive, royalty-free license to use, distribute,
        reproduce, modify, adapt, publish, translate, publicly perform, and
        publicly display such content.
        <br />
        Prohibited Activities: Users must not engage in activities that violate
        the rights of others, including intellectual property rights. Users must
        not attempt to gain unauthorized access to our website, servers or
        linked databases. Users must not use our website for illegal purposes or
        in violation of applicable laws.
        <br />
        Personal information: Users are responsible for maintaining the
        confidentiality of their account information, including their passwords.
        Users should not share their passwords or personal information with
        third parties. Data security: Although we take reasonable precautions to
        protect your data, we cannot guarantee complete security. Users
        acknowledge that any data submitted to our website may be subject to
        unauthorized access.
        <br />
        No Warranty: bsocial.today does not guarantee the accuracy, completeness
        or usefulness of any information on the site. Users use the site at
        their own risk. All content and services are provided "as is" without
        warranties of any kind, express or implied. Limitation of Liability:
        bsocial.today will not be liable for any direct, indirect, incidental,
        special, consequential or exemplary damages arising from the use or
        inability to use the website.
        <br />
        No Request for Money or Password: Our developers or team members will
        never ask for money, passwords or other sensitive personal information.
        Such requests must be reported immediately. Transactions: The risk of
        any transaction carried out through our website belongs to the user.
        bsocial.today is not responsible for any loss or damage arising from
        your dealings with third parties.
        <br />
        Right of Termination: bsocial.today reserves the right to terminate or
        suspend access to the website at any time, without notice, for any
        reason, including breach of these terms and conditions. Effects of
        Termination: Upon termination, all provisions of these terms which by
        their nature should survive termination shall survive termination,
        including ownership provisions, warranty disclaimers, indemnity and
        limitations of liability.
        <br />
        bsocial.today reserves the right to change these terms and conditions at
        any time. Users are encouraged to review these terms periodically.
        Continued use of the website after any changes constitutes acceptance of
        the new terms.
        <br />
        These terms and conditions are governed by and construed in accordance
        with the individual's local laws and any disputes relating to these
        terms will be subject to the exclusive jurisdiction of the individual's
        local courts.
        <br />
        If you have any questions about these terms and conditions, please
        contact us at admin@bsocial.today. By using bsocial.today you confirm
        that you have read, understood and agree to be bound by these terms and
        conditions.
      </p>
      <br />
      <h1>Koşullar ve Kullanım Şartları</h1>
      <p>
        Web sitemize erişerek veya web sitemizi kullanarak, aşağıdaki hüküm ve
        koşullara uymayı ve bunlara bağlı kalmayı kabul etmiş olursunuz. Lütfen
        hizmetlerimizi kullanmadan önce bu şartları dikkatlice okuyunuz. Bu
        şartların herhangi bir bölümünü kabul etmiyorsanız web sitemizi
        kullanmamalısınız.
        <br />
        Uygunluk: Web sitemize kayıt olmak ve kullanmak için kullanıcıların en
        az 18 yaşında olmaları gerekmektedir. 18 yaşından küçüklerin bu siteyi
        kullanmasına izin verilmemektedir.
        <br />
        Kullanıcı Davranışı: Kullanıcılar yasa dışı, zararlı, tehdit edici,
        küfürlü, taciz edici, iftira niteliğinde, kaba, müstehcen, cinsel açıdan
        müstehcen veya başka bir şekilde sakıncalı herhangi bir içerik
        yayınlamamalı veya iletmemelidir. Kullanıcılar ırk, etnik köken,
        cinsiyet, cinsel yönelim, din veya diğer korunan özelliklere dayalı
        taciz veya ayrımcılık teşkil eden herhangi bir davranışta
        bulunmamalıdır.
        <br />
        İçerik Sahipliği: Kullanıcılar içerik göndererek bsocial.today'e dünya
        çapında, münhasır olmayan, telifsiz bir kullanım, dağıtma, çoğaltma,
        değiştirme, uyarlama, yayınlama, tercüme etme, kamuya açık olarak
        gerçekleştirme, ve bu tür içerikleri herkese açık olarak sergileme izni
        verir.
        <br />
        Yasaklanan Faaliyetler: Kullanıcılar, fikri mülkiyet hakları da dahil
        olmak üzere başkalarının haklarını ihlal eden faaliyetlerde
        bulunmamalıdır. Kullanıcılar web sitemize, sunucularımıza veya
        bağlantılı veritabanlarına yetkisiz erişim sağlamaya çalışmamalıdır.
        Kullanıcılar web sitemizi yasa dışı amaçlarla veya yürürlükteki yasaları
        ihlal edecek şekilde kullanmamalıdır.
        <br />
        Kişisel bilgi: Kullanıcılar, şifreleri de dahil olmak üzere hesap
        bilgilerinin gizliliğini korumaktan sorumludur. Kullanıcılar şifrelerini
        veya kişisel bilgilerini üçüncü şahıslarla paylaşmamalıdır. Veri
        güvenliği: Verilerinizi korumak için makul önlemler alsak da tam
        güvenliği garanti edemeyiz. Kullanıcılar, web sitemize gönderilen
        herhangi bir verinin yetkisiz erişime maruz kalabileceğini kabul eder.
        <br />
        Garanti Yok: bsocial.today sitedeki herhangi bir bilginin doğruluğunu,
        eksiksizliğini veya kullanışlılığını garanti etmez. Kullanıcıların
        siteyi kullanma riski kendilerine aittir. Tüm içerik ve hizmetler, açık
        veya zımni hiçbir garanti olmaksızın "olduğu gibi" sağlanmaktadır.
        Sorumluluğun Sınırlandırılması: bsocial.today, web sitesinin
        kullanımından veya kullanılamamasından kaynaklanan doğrudan, dolaylı,
        arızi, özel, sonuç olarak ortaya çıkan veya örnek teşkil eden
        zararlardan sorumlu olmayacaktır.
        <br />
        Para veya Şifre Talebi Yok: Geliştiricilerimiz veya ekip üyelerimiz asla
        para, şifre veya diğer hassas kişisel bilgileri talep etmeyecektir. Bu
        tür talepler derhal rapor edilmelidir. İşlemler: Web sitemiz üzerinden
        gerçekleştirilen her türlü işlemin riski kullanıcıya aittir.
        bsocial.today üçüncü şahıslarla yapılan işlemlerden kaynaklanan hiçbir
        kayıp veya hasardan sorumlu değildir.
        <br />
        Fesih Hakkı: bsocial.today, bu şart ve koşulların ihlali de dahil olmak
        üzere herhangi bir nedenle, herhangi bir zamanda, bildirimde
        bulunmaksızın web sitesine erişimi sonlandırma veya askıya alma hakkını
        saklı tutar. Feshin Etkileri: Fesih üzerine, mülkiyet hükümleri, garanti
        reddi beyanları, tazminat ve sorumluluk sınırlamaları da dahil olmak
        üzere, doğası gereği fesihten sonra da geçerli olması gereken bu
        şartların tüm hükümleri geçerliliğini sürdürecektir.
        <br />
        bsocial.today bu şart ve koşulları istediği zaman değiştirme hakkını
        saklı tutar. Kullanıcıların bu şartları periyodik olarak incelemeleri
        önerilir. Herhangi bir değişiklikten sonra web sitesinin kullanılmaya
        devam edilmesi, yeni şartların kabul edildiği anlamına gelir.
        <br />
        Bu şartlar ve koşullar, kişinin yerel yasalarına tabidir ve bu yasalara
        uygun olarak yorumlanır ve bu şartlarla ilgili tüm anlaşmazlıklar,
        kişinin yerel mahkemelerinin münhasır yargı yetkisine tabi olacaktır.
        <br />
        Bu şartlar ve koşullar hakkında herhangi bir sorunuz varsa lütfen
        admin@bsocial.today adresinden bizimle iletişime geçin. bsocial.today'i
        kullanarak bu hüküm ve koşulları okuduğunuzu, anladığınızı ve bunlara
        bağlı kalmayı kabul ettiğinizi onaylamış olursunuz.
      </p>
    </>
  );
}

export default Terms;
