import {useState, useEffect} from 'react';
import {s3Prefix} from './env';
import {deepSetMap, date2DateString, date2DatetimeString} from './helpers';
import {InputButton, InputNumber, InputSelect} from './Inputs';
import {getAnswers, acceptPerson, rejectPerson, removePerson, 
  getEmbedding, invitePerson, getHarmonyAnalysis} from './requests';
import {getCharacteristicScore, getHarmonyScore, HarmonyTable} from './harmony';
import './Person.css';

function Person({personData, setPeoplesData, userData, eventData, 
  isApplicant, isAttender, setApplicantsData, setAttendersData, 
  setEventsData, point, setPointFormMap, usersFutureEvents}) {
  const [answers, setAnswers] = useState([]);
  const [analysisRequested, setAnalysisRequested] = useState(false);
  const [embedding, setEmbedding] = useState(null);
  const [harmonyAnalysis, setHarmonyAnalysis] = useState(null);
  const [formMap, setFormMap] = useState(new Map([['inviteSelect-' + personData.pid, '0:']]));

  function futureEventString(i, futureEvent) {
    return i + ': ' + futureEvent.title + ' ' + 
      date2DatetimeString(new Date(parseInt(futureEvent.eventtime)))
  }

  function handleAcceptPersonClick(e) {
    e.preventDefault();
    acceptPerson(userData.pid, userData.password, eventData.eid, personData.pid);
    setEventsData(prevEventsData => {
      const eventsData = [];
      for (let prevEventData of prevEventsData) {
        if (prevEventData.eid === eventData.eid) {
          prevEventData.applicants = 
            prevEventData.applicants.filter(applicantPID => applicantPID !== personData.pid);
          prevEventData.attenders.push(personData.pid);
        }
        eventsData.push(prevEventData);
      }
      return eventsData;
    });
    setApplicantsData(prevApplicantsData => 
      prevApplicantsData.filter(prevApplicantData => prevApplicantData.pid !== personData.pid));
    setAttendersData(prevAttendersData => {
      if (prevAttendersData.length > 0) {
        return [...prevAttendersData, personData];
      } else {
        return [];
      }
    });
  }

  function handleRejectApplicantClick(e) {
    e.preventDefault();
    rejectPerson(userData.pid, userData.password, eventData.eid, personData.pid);
    setEventsData(prevEventsData => {
      const eventsData = [];
      for (let prevEventData of prevEventsData) {
        if (prevEventData.eid === eventData.eid) {
          prevEventData.applicants = 
            prevEventData.applicants.filter(applicantPID => applicantPID !== personData.pid);
        }
        eventsData.push(prevEventData);
      }
      return eventsData;
    });
    setApplicantsData(prevApplicantsData => prevApplicantsData.filter(
      prevApplicantData => prevApplicantData.pid !== personData.pid));
  }

  function handleRemoveAttenderClick(e) {
    e.preventDefault();
    removePerson(userData.pid, userData.password, eventData.eid, personData.pid);
    setEventsData(prevEventsData => {
      const eventsData = [];
      for (let prevEventData of prevEventsData) {
        if (prevEventData.eid !== eventData.eid) {
          eventsData.push(prevEventData);
        } else {
          prevEventData.attenders = 
            prevEventData.attenders.filter(attenderPID => attenderPID !== personData.pid);
          eventsData.push(prevEventData);
        }
      }
      return eventsData;
    });
    setAttendersData(prevAttendersData => prevAttendersData.filter(
      prevAttenderData => prevAttenderData.pid !== personData.pid));
  }

  function handlePointChange({target}) {
    setPointFormMap(prevFormMap => {
      if (target.value < 0) {
        target.value = 0;
      } else if (4 < target.value && target.value <= 5) {
        target.value = 4;
      } else if (5 < target.value && target.value < 6) {
        target.value = 6;
      } else if (10 < target.value) {
        target.value = 10;
      }
      
      return deepSetMap(prevFormMap, personData.pid, target.value);
    });
  }

  function handleInviteChange({target}) {
    setFormMap(prevFormMap => deepSetMap(prevFormMap, target.id, target.value));
  }

  function handleInviteClick(e) {
    e.preventDefault();
    const selectValue = formMap.get('inviteSelect-' + personData.pid);
    const invitedEID = usersFutureEvents[parseInt(selectValue.split(':')[0])].eid;
    if (!personData.invitedevents.includes(invitedEID)) {
      invitePerson(userData.pid, userData.password, invitedEID, personData.pid);
      setPeoplesData(prevPeoplesData => {
        const peoplesData = [];
        for (let prevPersonData of prevPeoplesData) {
          if (prevPersonData.pid == personData.pid) {
            prevPersonData.invitedevents.push(invitedEID);
            prevPersonData.invitedcount += 1;
          }
          peoplesData.push(prevPersonData);
        }

        return peoplesData;
      });
    }
  }

  async function handleShowAnswersClick(e) {
    e.preventDefault();
    if (userData.pid == 0) {
      alert('Lütfen önce giriş yapın.');
      return;
    }
    if (answers.length > 0) {
      setAnswers([]);
    } else {
      setAnswers(await getAnswers(userData.pid, userData.password, personData.pid));
    }
  }

  async function handleShowHarmonyClick(e) {
    e.preventDefault();
    if (userData.pid == 0) {
      alert('Lütfen önce giriş yapın.');
      return;
    }
    if (embedding) {
      setEmbedding(null);
      setAnalysisRequested(false);
    } else {
      setAnalysisRequested(true);
      let results = await Promise.all([
        getEmbedding(userData.pid, userData.password, 'person', personData.pid),
        getHarmonyAnalysis(userData.pid, userData.password, personData.pid)
      ]);

      const embedding = results[0];
      setEmbedding(eval(embedding));

      const analysis = results[1];
      setHarmonyAnalysis(analysis);
      setAnalysisRequested(false);
    }
  }
  
  return (
    <>
      <div className='person'>
        <img loading='lazy' alt='person' src={s3Prefix + personData.urls[0]} />
        <h3>{personData.name} {personData.surname}</h3>
        <p>{date2DateString(new Date(parseInt(personData.dob)))}</p>
        <p>{personData.city}, {personData.country}</p>
        {eventData && isAttender && eventData.attenders.includes(userData.pid) &&
          <p>{personData.phone}</p>
        }
        <p>{personData.email}</p>
        <p>Katıldığı tarih: {date2DatetimeString(new Date(parseInt(personData.time)))}</p>
        <p>Katıldığı etkinlik sayısı: {personData.acceptedcount}</p>
        <p>Davet edildiği etkinlik sayısı: {personData.invitedcount}</p>
        <p>Son cevap verilen zaman: {parseInt(personData.answertime) ?
          date2DatetimeString(new Date(parseInt(personData.answertime))):'?'}</p>
        <p>Son algoritma güncellenen zaman: {parseInt(personData.embeddingtime) ?
          date2DatetimeString(new Date(parseInt(personData.embeddingtime))):'?'}</p>
        <br />
        <p>{personData.statement}</p>
        <br />
        <p><b>Ortalama puanı:</b> {personData.avgpoint?.toFixed(1) || '?'}</p>
        {answers.length > 0 &&
          answers.map((answerObj, i) => <p key={'answer-' + i} ><b>{
            'Soru ' + (i + 1) + ': '}</b> {answerObj.answer}</p>)
        }
        {analysisRequested && <p><b>Analiz yükleniyor...</b></p>}
        {embedding &&
          <div className='harmonyAnalysis'>
            <h3>Uyum Analizi</h3>
            <p><b>Karakteristik Puanı:</b> {getCharacteristicScore(embedding)}</p>
            <p><b>Uyum Puanı:</b> {getHarmonyScore(userData.embedding, embedding)}</p>
            <p><b>Uyum Analizi:</b> {harmonyAnalysis}</p>
            <HarmonyTable userEmbedding={userData.embedding} 
              otherEmbedding={embedding} />
          </div>
        }
        <InputButton id={'showAnswersButton'} 
          onClick={handleShowAnswersClick} value='Soru Cevapları' />
        {!analysisRequested &&
          <InputButton id={'showHarmonyButton'} 
            onClick={handleShowHarmonyClick} value='Uyum' />
        }
        {isApplicant &&
          <>
            <InputButton id={'reject-' + personData.pid + '-to-' + eventData.eid + '-Button'} 
              onClick={handleRejectApplicantClick} value='Reddet' />
            <InputButton id={'accept-' + personData.pid + '-to-' + eventData.eid + '-Button'} 
              onClick={handleAcceptPersonClick} value='Kabul Et' />
          </>
        }
        {isAttender &&
          <>
            <InputButton id={'remove-' + personData.pid + '-to-' + eventData.eid + '-Button'} 
              onClick={handleRemoveAttenderClick} value='Listeden Çıkar' />
          </>
        }
        {setPointFormMap && 
          <>
            <br />
            Puan: 
            <InputNumber id={'point-' + personData.pid} onChange={handlePointChange}
              value={point || ''} />
          </>
        }
        {usersFutureEvents && usersFutureEvents.length > 0 && 
          <>
            <br />
            <InputSelect id={'inviteSelect-' + personData.pid} 
              options={usersFutureEvents.map((e, i) => futureEventString(i, e))}
              onChange={handleInviteChange} value={formMap.get('invitedEID')}
            />
            {!personData.invitedevents.includes(usersFutureEvents[
              parseInt(formMap.get('inviteSelect-' + personData.pid)
                .split(':')[0])].eid) && 
              <InputButton id={'invite-' + personData.pid + '-Button'} 
                value='Davet Et' onClick={handleInviteClick}
              />
            }
          </>
        }
        {usersFutureEvents && usersFutureEvents.length > 0 && personData.invitedevents.includes(
          usersFutureEvents[parseInt(formMap.get('inviteSelect-' + personData.pid).split(':')[0])].eid) && 
          <>Etkinliğe önceden davet edildi</>
        }
      </div>
      <hr />
    </>
  );
}

export default Person;
