import {useState} from 'react';
import {InputTextArea, InputButton} from './Inputs';
import {deepSetMap} from './helpers';
import {giveFeedback} from './requests';

function GiveFeedbackView({userData, setCurrentView}) {
  const [formMap, setFormMap] = useState(new Map());

  function handleFeedbackChange({target}) {
    setFormMap(prevFormMap => deepSetMap(prevFormMap, target.id, target.value));
  }

  function handleSubmitFeedbackClick(e) {
    e.preventDefault();
    formMap.set('pid', userData.pid);
    formMap.set('password', userData.password);
    giveFeedback(formMap);
    setCurrentView('UserView');
  }

  return (
    <>
      <InputTextArea id='FeedbackTextArea' onChange={handleFeedbackChange}
        value={formMap.get('FeedbackTextArea')} />
      <br />
      <InputButton id='SubmitFeedbackButton' onClick={handleSubmitFeedbackClick} 
        value='Önerini yolla' />
    </>
  );
}

export default GiveFeedbackView;
