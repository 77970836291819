import {useState} from 'react';

// Components
import {InputButton, InputEmail, InputPassword} from './Inputs';

// Functions
import {loginUserEmail, resendPassword, getAnswers} from './requests';
import {deepSetMap} from './helpers';
import {checkLoginForm} from './checkForm';

// Styles
import './LoginForm.css';

function LoginForm({setIsLoggedIn, setCurrentView, setUserData, coords, 
  setUserAnswers}) {
  const [formMap, setFormMap] = useState(new Map());
  const [messages, setMessages] = useState(new Map());
  const [areFieldsReady, setAreFieldsReady] = useState(false);

  function handleChange({target}) {
    setFormMap(prevFormMap => {
      const formMap = deepSetMap(prevFormMap, target.id, target.value);
      checkLoginForm(formMap, setMessages, setAreFieldsReady);
      return formMap;
    });
  }

  async function handleLoginClick(e) {
    e.preventDefault();
    if (!areFieldsReady) {
      setMessages(prevMessages => deepSetMap(prevMessages, 'logButton', 
        'Lütfen girdileri kontrol edin.'));
      return;
    }
    try {
      formMap.set('lat', coords.lat);
      formMap.set('lng', coords.lng);
      const resObj = await loginUserEmail(formMap);
      const answers = await getAnswers(resObj.pid, resObj.password, resObj.pid);
      setUserAnswers(answers);
      localStorage.isLoggedIn = 'true';
      localStorage.pid = resObj.pid;
      localStorage.password = resObj.password;
      resObj.embedding = eval(resObj.embedding);
      setUserData(resObj);
      setIsLoggedIn(true);
      setCurrentView('UserView');
    } catch(e) {alert(e)};
  }

  function handleRegistrationViewClick(e) {
    e.preventDefault();
    setCurrentView('RegistrationView')
  }

  function handleForgotPasswordClick(e) {
    e.preventDefault();
    const email = formMap.get('logEmail');
    if (email && !messages.get('logEmail')) {
      resendPassword(email);
      setMessages(new Map([['forgotPasswordButton', 'Şifren email adresine yollandı.']]));
    }
  }

  return (
    <div className='loginForm'>
      <h3>Giriş Yap</h3>
      <p>Email</p>
      <InputEmail id='logEmail' onChange={handleChange} 
        value={formMap.get('logEmail') || ''} /><br />
      <p>Şifre</p>
      <InputPassword id='logPassword' onChange={handleChange} 
        value={formMap.get('logPassword') || ''} /><br />
      <InputButton id="logButton" message={messages.get('logButton')}
        value='Giriş Yap' onClick={handleLoginClick} />
      <InputButton value='Şifremi Unuttum' id='forgotPasswordButton'
        message={messages.get('forgotPasswordButton')} 
        onClick={handleForgotPasswordClick} />
      <InputButton value='Kayıt Ol' id='registrationViewButton'
        onClick={handleRegistrationViewClick} />
    </div>
  )
}

export default LoginForm;
