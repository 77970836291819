const cl = console.log;

function deepSetMap(map, key, val) {
  const newMap = new Map(map);
  return newMap.set(key, val);
}

function getCoords() {
  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(resObj => {
      resolve({lat: resObj.coords.latitude, lng: resObj.coords.longitude});
    }, () => {
      resolve({lat: 0, lng: 0});
    }, {
      maximumAge: 10000,
      timeout: 5000,
      enableHighAccuracy: true
    });
  });
}

function formMap2FormData(formMap) {
  const formData = new FormData();
  for (let key of formMap.keys()) {
    formData.set(key, formMap.get(key));
  }
  return formData;
}

function formMap2Array(formMap) {
  const array = [];
  for (let key of formMap.keys()) {
    array.push({key: key, value: formMap.get(key)});
  }
  return array;
}

function array2FormMap(array) {
  const formMap = new Map();
  for (let obj of array) {
    formMap.set(obj.key, obj.value);
  }
  return formMap;
}

function logOut(setIsLoggedIn, setUserData, setCurrentView, setUserAnswers) {
  cl('Logging out the user...');
  localStorage.isLoggedIn = 'false';
  localStorage.pid = '0';
  localStorage.password = 'password';
  setIsLoggedIn(false);
  setUserData({pid: 0, password: 'password'});
  setCurrentView('IntroView');
  setUserAnswers([]);
}

function padZeros(integer, count) {
  if (integer < 10) {
    return '0' + integer;
  }
  return integer;
}

function date2DateString(dateObj) {
  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1;
  const year = dateObj.getUTCFullYear();
  /*const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();*/
  return padZeros(day) + '/' + padZeros(month) + '/' + year;
}

function date2DatetimeString(dateObj) {
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  /*const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();*/
  const dateString = date2DateString(dateObj);
  return dateString + ' '  + padZeros(hours) + ':' + padZeros(minutes);
}

export {cl, getCoords, formMap2FormData, logOut, deepSetMap, date2DateString, 
  formMap2Array, array2FormMap, date2DatetimeString};
