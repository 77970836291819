import SetAnswersForm from './SetAnswersForm';

function SetAnswersView({userData, setUserData, setCurrentView, userAnswers, 
  setUserAnswers}) {
  return (
    <SetAnswersForm userData={userData} setUserData={setUserData} 
      setCurrentView={setCurrentView} userAnswers={userAnswers} 
      setUserAnswers={setUserAnswers} />
  );
}

export default SetAnswersView;
