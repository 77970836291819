import {useState, useEffect} from 'react';
import {InputText, InputNumber, InputSelect, InputPhoto, InputDate, 
  InputButton, InputDatetime, InputTextArea} from './Inputs';
import {checkAddEventForm} from './checkForm';
import {deepSetMap} from './helpers';
import MyMap from './MyMap';
import {addEvent} from './requests';
import './AddEventForm.css';

function AddEventForm({userData, setCurrentView}) {
  const [formMap, setFormMap] = useState(new Map([['addEventType', 'sohbet']]));
  const [messages, setMessages] = useState(new Map());
  const [areFieldsReady, setAreFieldsReady] = useState(false);
  const [isPhotoReady, setIsPhotoReady] = useState(false);
  const [myMap, setMyMap] = useState();
	const options = [
		'sohbet',
    'açık hava',
		'cafe',
		'restoran',
		'bar',
		'gece kulübü',
    'parti',
		'spor',
		'çalışma',
		'seyahat',
		'konser',
		'sanat',
		'müze',
		'diğer'
	];

  useEffect(() => {
    setMyMap(new MyMap('addEventMap'));
  }, []);

  function handleChange({target}) {
    setFormMap(prevFormMap => {
      const formMap = deepSetMap(prevFormMap, target.id, target.value);
      checkAddEventForm(formMap, setMessages, setAreFieldsReady, setIsPhotoReady);
      return formMap;
    });
  }

  function handleChangePhoto({target}) {
    setFormMap(prevFormMap => {
      const formMap = deepSetMap(prevFormMap, target.id, target.files[0]);
      checkAddEventForm(formMap, setMessages, setAreFieldsReady, setIsPhotoReady);
      return formMap;
    });
  }

  function handleAddEventClick(e) {
    e.preventDefault();
    if(!areFieldsReady || !isPhotoReady) {
      setMessages(prevMessages => deepSetMap(prevMessages, 'addEventButton', 
          'Lütfen girdileri kontrol edin.'));
      return;
    }
    const coords = myMap.getPosition();
    formMap.set('pid', userData.pid);
    formMap.set('password', userData.password);
    formMap.set('lat', coords.lat);
    formMap.set('lng', coords.lng);
    addEvent(formMap)
      .then(() => {setCurrentView('UserView')})
      .catch(error => alert(error));
  }

  return (
    <div className='addEventForm'>
      <h3>Etkinlik Ekle</h3>
      <p>Başlık</p>
      <InputText id='addEventTitle' onChange={handleChange} 
        value={formMap.get('addEventTitle') || ''} 
        message={messages.get('addEventTitle')} />
      <p>Fotoğraf</p>
      <InputPhoto id='addEventPhoto' onChange={handleChangePhoto}
        message={messages.get('addEventPhoto')} />
      <p>Etkinlik Tipi</p>
      <InputSelect id='addEventType' options={options} onChange={handleChange}
        value={formMap.get('addEventType') || ''} 
        message={messages.get('addEventType')} />
      <p>Açıklama</p>
      <InputTextArea id='addEventDescription' onChange={handleChange} 
        value={formMap.get('addEventDescription') || ''} 
        message={messages.get('addEventDescription')} />
      <p>Maksimum Kişi Sayısı</p>
      <InputNumber id='addEventMaxAttendeeCount' onChange={handleChange}
        value={formMap.get('addEventMaxAttendeeCount') || ''} 
        message={messages.get('addEventMaxAttendeeCount')} />
      <p>Tarih</p>
      <InputDatetime id='addEventTime' onChange={handleChange}
        value={formMap.get('addEventTime') || ''} 
        message={messages.get('addEventTime')} />
      <p>Lokasyon</p>
      <div id='addEventMap'></div>
      <InputButton id='addEventButton' onClick={handleAddEventClick}
        value='Etkinlik Ekle' message={messages.get('addEventButton')} />
    </div>
  );
}

export default AddEventForm;
