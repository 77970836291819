import {useState} from 'react';
import {searchEvents} from './requests';
import {InputText, InputButton} from './Inputs';
import {deepSetMap} from './helpers';

function EventSearchForm({userData, setSEventsData}) {
  const [formMap, setFormMap] = useState(new Map());

  function handleQueryChange({target}) {
    setFormMap(prevFormMap => deepSetMap(prevFormMap, target.id, target.value));
  }

  async function handleSearchClick(e) {
    e.preventDefault();
    formMap.set('pid', userData.pid);
    formMap.set('password', userData.password);
    const searchResults = await searchEvents(formMap);
    setSEventsData(searchResults);
  }

  return (
    <>
      <InputText id='eventSearchQuery' onChange={handleQueryChange} 
        value={formMap.get('eventSearchQuery') || ''} placeholder='Ara' />
      <InputButton id='eventSearchButton' onClick={handleSearchClick} value={'Etkinlik Ara'} />
    </>
  );
}

export default EventSearchForm;
