import {useState, useEffect} from 'react';
import User from './User';
import Event from './Event';
import {getSavedEvents} from './requests';
import './UserView.css';

function UserView({userData, setUserData, userAnswers}) {
  const [savedEventsData, setSavedEventsData] = useState([]);
  const [invitedEventsData, setInvitedEventsData] = useState([]);

  useEffect(() => {
    getSavedEvents(userData.pid, userData.password)
      .then(savedEvents => setSavedEventsData(savedEvents))
      .catch(e => alert(e));
  }, []);

  return (
    <div className='userView'>
      <User userData={userData} setUserData={setUserData} invitedEventsData={invitedEventsData}
        setInvitedEventsData={setInvitedEventsData} userAnswers={userAnswers} />
      {savedEventsData.length > 0 && 
        <>
          <h3>Kayıtlı Etkinlikler</h3>
          {
            savedEventsData.map((savedEventData, i) => 
              <Event key={'saved' + i} eventData={savedEventData} userData={userData} 
                setEventsData={setSavedEventsData} />)
          }
          <hr /><hr /><hr />
        </>
      }
      {invitedEventsData?.length > 0 && 
        <>
          <h3>Davet Edildiğin Etkinlikler</h3>
          {
            invitedEventsData.map((invitedEventData, i) => 
              <Event key={'invited' + i} eventData={invitedEventData} userData={userData} 
                setEventsData={setInvitedEventsData} isInvitedEvent={true} 
                setUserData={setUserData} />)
          }
          <hr /><hr /><hr />
        </>
      }
    </div>
  );
}

export default UserView;
