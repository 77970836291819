import {useState, useEffect} from 'react';
import EventSearchForm from './EventSearchForm';
import Event from './Event';
import {getRecommendedEvents} from './requests';
import {InputButton} from './Inputs';
import './EventsView.css';

function EventsView({userData, setCurrentView}) {
  const [sEventsData, setSEventsData] = useState([]);
  const [rEventsData, setREventsData] = useState([]);

  function getNewRecommendedEvents() {
    getRecommendedEvents(userData.pid, userData.password)
      .then(recommendedEvents => setREventsData(recommendedEvents))
      .catch(e => alert(e));
  }

  function handleAddEventViewClick(e) {
    e.preventDefault();
    if (userData.pid != 0) {
      setCurrentView('AddEventView');
    }
  }

  useEffect(() => {getNewRecommendedEvents()}, []);

  return (
    <div id='eventsView' >
      <div className='eventsViewTop'>
        <EventSearchForm userData={userData} setSEventsData={setSEventsData}/>
        <span></span>
        <InputButton id='addEventViewButton' onClick={handleAddEventViewClick} 
          value='Etkinlik Ekle' />
      </div>
      <hr /><hr /><hr />
      {sEventsData.length ? <h3>Arama Sonuçları</h3>:null}
      <div className='sEvents'>
        {sEventsData.map((eventData, i) => <Event key={i} eventData={eventData}
          userData={userData} setEventsData={setSEventsData} />)}
      </div>
      {sEventsData.length ? <><hr /><hr /></>:null}
      <h3>Öneriler</h3>
      <div className='rEvents'>
        {rEventsData.map((eventData, i) => <Event key={i} eventData={eventData}
          userData={userData} setEventsData={setREventsData} />)
        }
        {rEventsData.length === 0 && 
          <p>Yakınında etkinlik bulamadık... Sen oluşturmak ister misin?</p>
        }
      </div>
    </div>
  );
}

export default EventsView;
