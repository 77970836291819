import './HarmonyTable.css';

function innerProduct(a, b) {
  let res = 0;
  a.forEach((val, i) => {res += val*b[i]});
  return res.toFixed(1);
}

function getCharacteristicScore(embedding) {
  let characteristicScore = embedding.reduce((acc, val) => acc + Math.abs(val));
  return characteristicScore.toFixed(1);
}

function getHarmonyScore(userEmbedding, otherEmbedding) {
  return innerProduct(userEmbedding, otherEmbedding);
}

function HarmonyTable({userEmbedding, otherEmbedding, isUser}) {
  const harmonyScores = [], harmonyColors = [];
  const table_header = isUser ? 'Sorun':'Sorusu';
  let a, b, score, maxScore = 0, minScore = 0, red, green;

  if (!userEmbedding || !otherEmbedding) {
    return null;
  }

  for (let i = 0; i < 5; i++) {
    a = userEmbedding.slice(i*128, (i + 1)*128 - 1);
    harmonyScores.push([]);
    for (let j = 0; j < 5; j++) {
      b = otherEmbedding.slice(j*128, (j + 1)*128 - 1);
      score = innerProduct(a, b);
      maxScore = Math.max(maxScore, score);
      minScore = Math.min(minScore, score);
      harmonyScores[i].push(score);
    }
  }
  for (let i = 0; i < harmonyScores.length; i++) {
    harmonyColors.push([]);
    for (let j = 0; j < harmonyScores[i].length; j++) {
      score = harmonyScores[i][j];
      red = (1 - (score - minScore)/(maxScore - minScore))*255;
      green = (score - minScore)/(maxScore - minScore)*255;
      harmonyColors[i].push(`rgb(${red}, ${green}, 0)`);
    }
  }

  return (
      <table className='harmonyTable'>
        <thead>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <th>1. {table_header}</th>
            <th>2. {table_header}</th>
            <th>3. {table_header}</th>
            <th>4. {table_header}</th>
            <th>5. {table_header}</th>
          </tr>
          {
            harmonyScores.map((row, i) => {
              return (
                <tr key={'row-' + i}>
                  <>
                    <th>{i + 1}. Sorun</th>
                      {
                        row.map((score, j) => 
                          <td key={'' + i + j} style={{backgroundColor: harmonyColors[i][j]}}>
                          {score}
                          </td>
                        )
                      }
                  </>
                </tr>
              );
            })
          }
        </tbody>
      </table>
  );
}

export {getCharacteristicScore, HarmonyTable, getHarmonyScore};
