// React
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Components
import Header from "./Header";
import IntroView from "./IntroView";
import UserView from "./UserView";
import EventsView from "./EventsView";
import AddEventView from "./AddEventView";
import PeopleView from "./PeopleView";
import LoginView from "./LoginView";
import RegistrationView from "./RegistrationView";
import SettingsView from "./SettingsView";
import SetAnswersView from "./SetAnswersView";
import SetPointsView from "./SetPointsView";
import GiveFeedbackView from "./GiveFeedbackView";
import Terms from "./Terms";
import Footer from "./Footer";

// Functions
import { cl, getCoords, logOut } from "./helpers";
import { loginUserPID, getAnswers } from "./requests";

// Styles
import "./App.css";

// App
function App() {
  let view;
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({ pid: 0, password: "password" });
  const [userAnswers, setUserAnswers] = useState([]);
  const [currentView, setCurrentView] = useState("IntroView");

  async function mount() {
    cl("Mounting app...");
    setCurrentView("IntroView");
    const coords = await getCoords();
    setCoords(coords);
    if (localStorage.isLoggedIn === "true") {
      const resObj = await loginUserPID(
        localStorage.pid,
        localStorage.password,
        coords.lat,
        coords.lng
      );
      if (resObj.code == 1) {
        resObj.embedding = eval(resObj.embedding);
        setUserData(resObj);
        setIsLoggedIn(true);
        setCurrentView("UserView");
        const answers = await getAnswers(
          resObj.pid,
          resObj.password,
          resObj.pid
        );
        setUserAnswers(answers);
      } else {
        logOut(setIsLoggedIn, setUserData, setCurrentView);
      }
    }
  }

  useEffect(() => {
    mount();
  }, []); // Login at mount

  // Views
  switch (currentView) {
    case "IntroView":
      view = (
        <IntroView
          userData={userData}
          coords={coords}
          setCurrentView={setCurrentView}
        />
      );
      break;
    case "UserView":
      view = (
        <UserView
          userData={userData}
          setUserData={setUserData}
          userAnswers={userAnswers}
        />
      );
      break;
    case "EventsView":
      view = <EventsView userData={userData} setCurrentView={setCurrentView} />;
      break;
    case "AddEventView":
      view = (
        <AddEventView userData={userData} setCurrentView={setCurrentView} />
      );
      break;
    case "PeopleView":
      view = <PeopleView userData={userData} />;
      break;
    case "LoginView":
      view = (
        <LoginView
          setUserData={setUserData}
          setIsLoggedIn={setIsLoggedIn}
          setCurrentView={setCurrentView}
          coords={coords}
          setUserAnswers={setUserAnswers}
        />
      );
      break;
    case "RegistrationView":
      view = (
        <RegistrationView
          setUserData={setUserData}
          setIsLoggedIn={setIsLoggedIn}
          setCurrentView={setCurrentView}
          coords={coords}
        />
      );
      break;
    case "SettingsView":
      view = (
        <SettingsView
          userData={userData}
          setIsLoggedIn={setIsLoggedIn}
          setUserData={setUserData}
          setCurrentView={setCurrentView}
          setUserAnswers={setUserAnswers}
        />
      );
      break;
    case "SetAnswersView":
      view = (
        <SetAnswersView
          userData={userData}
          setUserData={setUserData}
          setCurrentView={setCurrentView}
          userAnswers={userAnswers}
          setUserAnswers={setUserAnswers}
        />
      );
      break;
    case "SetPointsView":
      view = (
        <SetPointsView
          userData={userData}
          setUserData={setUserData}
          setCurrentView={setCurrentView}
        />
      );
      break;
    case "GiveFeedbackView":
      view = (
        <GiveFeedbackView userData={userData} setCurrentView={setCurrentView} />
      );
      break;
    default:
      view = <IntroView />;
      break;
  }

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        userData={userData}
        currentView={currentView}
        setCurrentView={setCurrentView}
      />
      <div className="viewContainer">{view}</div>
      <Footer />
    </>
  );
}

export default App;
