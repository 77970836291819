import {useState, useEffect} from 'react';
import {InputButton} from './Inputs';
import {getIntroStats} from './requests';
import './IntroView.css';
import Terms from './Terms';

function IntroView({userData, coords, setCurrentView}) {
  const [answer, setAnswer] = useState('');
  const [stats, setStats] = useState(null);
  const answers = [
    'Çevrendeki insanları ve insanların planladığı etkinlikleri görmek, ilgini çekenlere katılmak ve unutulmaz anılar kazanmak için.',
    'Özel olarak dizayn edilmiş algoritmalarımız, kişilerin karakterlerini göz önünde bulundurarak önerilerde bulunur ve bulunduğu önerileri detaylı bir şekilde açıklar.',
    'Kişilerin sorulara verdiği cevapları ve aldıkları ortalama puanı inceleyerek güvenilir olup olmadıklarına karar verebilirsin. Yapılan şikayetler ile istenmeyen kullanıcıların erişimini engelleyeceğiz.',
    'Özel olarak dizayn edilmiş 5 soruya verdiğin cevapları ve diğer kullanıcılara verdiğin puanları istatiksel modeller ile analiz ediyoruz. Daha fazla kullanıcı katılıp geri bildirim sağladıkça doğruluğu artırmak için eşleştirme algoritmamızı sürekli olarak geliştiriyor ve optimize ediyoruz.',
    'Herhangi bir kullanıcı ile arandaki uyumu daha iyi anlaman için yaptığımız uyum analizinde birkaç sayısal değeri seninle paylaşıyoruz. Bu değerler karakteristik puanı, uyum puanı, uyum matrisidir ve hepsi sorulara verilen cevaplar ile hesaplanır. Tek başlarına çok birşey ifade etmeyen bu değerler, diğer insanlarla karşılaştırıldığında anlam kazanırlar. Karakteristik puanı bir kişinin ne kadar kuvvetli bir karakteri olduğunu ifade eder. Yüksek karakteristik puanı olan insanlar sıradışı veya kuvvetli tercihleri olan insanlar olarak değerlendirilebilir. Uyum puanı iki kişi arasındaki tahmini uyumu ifade eder. Uyum matrisi, uyum puanının detaylı bir versiyonu olup, iki kişinin sorulara verdiği cevapların birbiri arasındaki uyumunu ifade eder.',
    'Kullanıcılar yanıtlarını güncelleyerek eşleşmelerini etkileyebilmektedir. Eşleştirme deneyiminiz üzerinde daha fazla kontrol sahibi olmanızı sağlamak için daha kişiselleştirilmiş filtreler ve tercihler geliştiriyoruz.',
    'Herhangi bir konuda her yorumunu/isteğini duymayı isteriz! Bize admin@bsocial.today adresinden ulaşabilirsin.',
    'bsocial.today tamamen ücretsiz bir sistem, hiç bir servis için ödeme yapman istenmiyor.',
    'Giriş yaptıktan sonra ayarlar kısmından tek tuş ile bilgilerini silebilirsin.'
  ];

  useEffect(() => {
    async function mount() {
      const stats = 
        await getIntroStats(userData.pid, userData.password, coords.lat, coords.lng);
      setStats(stats);
    }
    mount();
  }, [coords.lat, coords.lng, userData.pid, userData.password]);

  function handleRegisterClick(e) {
    e.preventDefault();
    setCurrentView('RegistrationView');
  }

  function handleFAQClick(e) {
    e.preventDefault();
    const questionNum = parseInt(e.target.id.split('-')[0]);
    setAnswer(answers[questionNum]);
  }

  return (
    <div className='introView'>
      <div className='heroSection'>
        <div className='heroLeftColumn'>
          <p>sosyal etkinlikler, yeni insanlar</p>
          <h1>Harika etkinlikleri keşfet</h1>
          <h1>Yeni insanlarla tanış</h1>
          <h1>Hayatını renklendir!</h1>
          <h3>
            Sana özel olarak seçilmiş etkinliklere katılarak yeni insanlarla tanışmak ister misin? 
          </h3>
          <h3>
            Aktif bir sosyal yaşam, sağlığımız için çok önemli, ve biz yardımcı olmak için buradayız! Yapay zeka ile karakterine uygun etkinlikleri ve kişileri seninle eşleştiriyoruz ve aranızdaki uyumu açıklıyoruz.
          </h3>
          <h3>
            Kaydol, 5 kısa soruyu yanıtla ve harika insanlarla tanışmaya bugün başla!
          </h3>
          <InputButton value='Kaydolup Harika Etkinliklere Katılmaya Hazırım!' 
            id='registrationViewButton' onClick={handleRegisterClick} />
          <img alt='pointing hand' id='pointingHand' src='./point.gif' />
          {stats && 
            <>
              <p>Toplam kayıtlı etkinlik sayısı: {stats.totalEvents}</p>
              <p>Yakınındaki etkinlik sayısı: {stats.totalEvents}</p>
              <p>Toplam kullanıcı sayısı: {stats.totalPeople}</p>
              <p>Yakınındaki kullanıcı sayısı: {stats.nearbyPeople}</p>
            </>
          }
        </div>
        <div className='heroRightColumn'>
          <video autoPlay loop muted
            src='https://videos.pexels.com/video-files/5329615/5329615-uhd_1440_2732_25fps.mp4' />
        </div>
      </div>
      <div className='benefits'>
        <div className='benefit'>
          <span className="material-symbols-outlined">
          handshake
          </span>
          <h3>Aktif Sosyal Yaşam</h3>
          <p>
            Kişiselleştirilmiş önerilerle yakınındaki etkinlik ve insanları keşfet, eforsuz bir şekilde sosyal hayatında yeni bir çağa başla.
          </p>
        </div>
        <div className='benefit'>
          <span className="material-symbols-outlined">
          diversity_3
          </span>
          <h3>Sıkı Arkadaşlar</h3>
          <p>
            Yapay zekanın yaptığı açıklamalar ile sana önerilen kişilerle neden uyumlu olduğunu anla.
          </p>
        </div>
        <div className='benefit'>
          <span className="material-symbols-outlined">
          emoji_people
          </span>
          <h3>Doğru İnsanlar</h3>
          <p>
            Kişilerin sorulara verdiği cevaplar ve aldığı puanlar ile uyumlu olup olmadığınızı anlarken iç güdülerine değil veriye güven.
          </p>
        </div>
      </div>
      <div className='howDoesItWork'>
        <div className='howDoesItWorkText'>
          <h1>Nasıl</h1>
          <h1>çalışır?</h1>
        </div>
        <div className='howDoesItWorkList'>
          <ol>
            <li>
              <p>Web, iOS veya Android üzerinden kayıt ol</p>
              <InputButton value='Kaydolup Harika Etkinliklere Katılmaya Hazırım!' 
                id='2registrationViewButton' onClick={handleRegisterClick} />
            </li>
            <li>
              <p>Karakterini anlamak için sorulan 5 soruya olabildiğince detaylı cevap ver.</p>
            </li>
            <li>
              <p>Sana önerilen etkinliklere katıl ya da kendi etkinliğini oluşturup insanları davet et.</p>
            </li>
            <li>
              <p>Kurucusu tarafından kabul edildiğin etkinliğe katılmak için doğru zamanda doğru yerde ol ve yeni insanlarla tanışmanın heyecanını yaşa!</p>
            </li>
          </ol>
        </div>
      </div>
      <div className='testimonials'>
        <div className='testimonial'>
          <h3>Artık evimde hissediyorum!</h3>
          <p>
            Yeni bir şehre taşındım ve kimseyi tanımıyordum. bsocial.today sayesinde kısa sürede çevremde etkinlikler buldum ve harika insanlarla tanıştım.
          </p>
          <div className='testimonialSignature'>
            <img alt='a person' src='./person.jpg' />
            <b>Berkan Kadıoğlu</b><br />
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
          </div>
        </div>
        <div className='testimonial'>
          <h3>Güzel olmuş</h3>
          <p>
            Uygulamayı beğendim, mantıklı olmuş. Kullanıcı kitlesi arttıkça daha da faydalı olacaktır.
          </p>
          <div className='testimonialSignature'>
            <img alt='a person' src='./person.jpg' />
            <b>Ahmet Can</b><br />
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
          </div>
        </div>
        <div className='testimonial'>
          <h3>Yeni arkadaşlarım için teşekkürler!</h3>
          <p>
            İçe dönük biri olarak arkadaş edinmek zaman alan bir deneyimdi. Bana özel etkinlik önerileri ile ilginç insanlarla tanıştım.
          </p>
          <div className='testimonialSignature'>
            <img alt='a person' src='./person.jpg' />
            <b>Can Avar</b><br />
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
            <span className="material-symbols-outlined">
            grade
            </span>
          </div>
        </div>
      </div>
      <div className='aboutUs'>
        <div className='aboutUsText'>
          <h1>Merhaba, ben Berkan!</h1>
          <p>
            bsocial.today'i geliştirirken amacım insanların sosyal yaşamlarını güçlendirmekti. İçe dönük biri olarak, yeni insanlarla tanışmak benim için her zaman zor oldu. Yeni jenerasyonların bu durumdan önceki jenerasyonlara göre daha fazla etkilendiğini bilimsel araştırmalarda da okuyunca bu projeye başlamaya karar verdim. Yapay zeka ile öneri algoritmaları üzerine yaptığım çalışmalarımda edindiğim bilgileri ve web tasarımı alanındaki yeni yeteneklerimi bu projede bir araya getirdim. <b>Güçlü sosyal bağların en başta iç dünyamızı ve dolayısıyla da hayatımızın geri kalan her alanını derinden etkilediğine inanıyorum.</b> 
          </p>
          <p>
            Fikirlerin benim için çok önemli. bsocial.today'in gelişimine katkıda bulunmak için sorularını ve önerilerini <a href='mailto:admin@bsocial.today'>admin@bsocial.today</a> adresine iletebilirsin. Teşekkürler! 
          </p>
          <InputButton value='Kaydolup Harika Etkinliklere Katılmaya Hazırım!' 
            id='2registrationViewButton' onClick={handleRegisterClick} />
        </div>
        <div className='aboutUsImage'>
          <img alt='berkan' src='./berkan.jpg' />
        </div>
      </div>
      <div className='FAQs'>
        <div className='FAQsGrid'>
          <h1>Sıkça Sorulan Sorular</h1>
          <InputButton value='bsocial.today komünitesine neden katılmak isteyeyim?' 
            id='0-Button' onClick={handleFAQClick} />
          <InputButton value='Benzer diğer sistemlerden farkınız nedir?' 
            id='1-Button' onClick={handleFAQClick} />
          <InputButton value='Diğer kullanıcılara nasıl güvenebilirim?' 
            id='2-Button' onClick={handleFAQClick} />
          <InputButton value='Kaliteli öneriler nasıl yapılıyor?' 
            id='3-Button' onClick={handleFAQClick} />
          <InputButton value='Uyum analizinde neler kullanılıyor?' 
            id='4-Button' onClick={handleFAQClick} />
          <InputButton value='Önerileri değiştirmek için ne yapabilirim?' 
            id='5-Button' onClick={handleFAQClick} />
          <InputButton value='Yetkililer ile nasıl iletişime geçebilirim?' 
            id='6-Button' onClick={handleFAQClick} />
          <InputButton value='Ödeme yapmam gerekiyor mu?' 
            id='7-Button' onClick={handleFAQClick} />
          <InputButton value='Hesabımı nasıl silebilirim?' 
            id='8-Button' onClick={handleFAQClick} />
        </div>
        <p>{answer}</p>
      </div>
      <div className='FAQs'>
        <Terms />
      </div>
    </div>
  )
}

export default IntroView;
