import {url} from './env';
import {checkResponse} from './checkResponse';
import {cl, formMap2FormData} from './helpers';

function loginUserPID(pid, password, lat, lng) {
  cl('loginUserPID');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('lat', lat);
    formData.set('lng', lng);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'loginUserPID');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function registerUser(formMap) {
  cl('registerUser');
  return new Promise(resolve => {
    const formData = formMap2FormData(formMap);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'registerUser');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function loginUserEmail(formMap) {
  cl('loginUserEmail');
  return new Promise(resolve => {
    const form = formMap2FormData(formMap);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'loginUserEmail');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(form);
  });
}

function deleteUser(pid, password) {
  cl('deleteUser');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'deleteUser');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function searchEvents(formMap) {
  cl('searchEvents');
  return new Promise(resolve => {
    const formData = formMap2FormData(formMap);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'searchEvents');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.events)};
    }

    xhr.send(formData);
  });
}

function searchPeople(formMap) {
  cl('searchPeople');
  return new Promise(resolve => {
    const formData = formMap2FormData(formMap);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'searchPeople');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.people)};
    }

    xhr.send(formData);
  });
}

function getRecommendedEvents(pid, password) {
  cl('getRecommendedEvents');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getRecommendedEvents');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.events)};
    }

    xhr.send(formData);
  });
}

function getRecommendedPeople(pid, password) {
  cl('getRecommendedPeople');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getRecommendedPeople');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.people)};
    }

    xhr.send(formData);
  });
}

function cancelEvent(pid, password, eid) {
  cl('cancelEvent');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('eid', eid);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'cancelEvent');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function leaveEvent(pid, password, eid) {
  cl('leaveEvent');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('eid', eid);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'leaveEvent');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function joinEvent(pid, password, eid) {
  cl('joinEvent');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('eid', eid);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'joinEvent');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function addEvent(formMap) {
  cl('addEvent');
  return new Promise(resolve => {
    const formData = formMap2FormData(formMap);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'addEvent');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function getSavedEvents(pid, password) {
  cl('getSavedEvents');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getSavedEvents');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.events)};
    }

    xhr.send(formData);
  });
}

function getPerson(pid, password, pid2) {
  cl('getPerson');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('pid2', pid2);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getPerson');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function getPeople(pid, password, pids) {
  cl('getPeople');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('pids', pids);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getPeople');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.people)};
    }

    xhr.send(formData);
  });
}

function getQuestions(pid, password) {
  cl('getQuestions');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getQuestions');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.questions)};
    }

    xhr.send(formData);
  });
}

function getAnswers(pid, password, pid2) {
  cl('getAnswers');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('pid2', pid2);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getAnswers');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.answers)};
    }

    xhr.send(formData);
  });
}

function setAnswers(formMap) {
  cl('setAnswersDB');
  return new Promise(resolve => {
    const formData = formMap2FormData(formMap);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'setAnswers');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function acceptPerson(pid, password, eid, pid2) {
  cl('acceptPerson');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('eid', eid);
    formData.set('pid2', pid2);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'acceptPerson');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function rejectPerson(pid, password, eid, pid2) {
  cl('rejectPerson');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('eid', eid);
    formData.set('pid2', pid2);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'rejectPerson');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function removePerson(pid, password, eid, pid2) {
  cl('removePerson');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('eid', eid);
    formData.set('pid2', pid2);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'removePerson');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function getEmbedding(pid, password, type, id) {
  cl('getEmbedding');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('type', type);
    formData.set('id', id);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getEmbedding');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(eval(resObj.embedding))};
    }

    xhr.send(formData);
  });
}

function getInteractedPeople(pid, password) {
  cl('getInteractedPeople');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getInteractedPeople');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.people)};
    }

    xhr.send(formData);
  });
}

function getPoints(pid, password) {
  cl('getPoints');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getPoints');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.points)};
    }

    xhr.send(formData);
  });
}

function setPoints(formMap) {
  cl('setPoints');
  return new Promise(resolve => {
    const formData = formMap2FormData(formMap);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'setPoints');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function invitePerson(pid, password, eid, pid2) {
  cl('invitePerson');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('eid', eid);
    formData.set('pid2', pid2);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'invitePerson');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function getInvitedEvents(pid, password) {
  cl('getInvitedEvents');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getInvitedEvents');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.events)};
    }

    xhr.send(formData);
  });
}

function declineInvitation(pid, password, eid) {
  cl('declineInvitation');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('eid', eid);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'declineInvitation');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function declineAllInvitations(pid, password) {
  cl('declineAllInvitations');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'declineAllInvitations');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function getUsersFutureEvents(pid, password) {
  cl('getUsersFutureEvents');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getUsersFutureEvents');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.events)};
    }

    xhr.send(formData);
  });
}

function getHarmonyAnalysis(pid, password, pid2) {
  cl('getHarmonyAnalysis');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('pid2', pid2);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getHarmonyAnalysis');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj.analysis)};
    }

    xhr.send(formData);
  });
}

function getIntroStats(pid, password, lat, lng) {
  cl('getIntroStats');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('pid', pid);
    formData.set('password', password);
    formData.set('lat', lat);
    formData.set('lng', lng);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'getIntroStats');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function resendPassword(email) {
  cl('resendPassword');
  return new Promise(resolve => {
    const formData = new FormData();
    formData.set('email', email);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'resendPassword');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

function onReadyStateChange(xhr) {
  if (xhr.readyState === XMLHttpRequest.DONE) {
    const resObj = JSON.parse(xhr.responseText);
    checkResponse(resObj);
    return resObj;
  }
  return false;
}

function giveFeedback(formMap) {
  cl('giveFeedback');
  return new Promise(resolve => {
    const formData = formMap2FormData(formMap);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url + 'giveFeedback');
    xhr.onreadystatechange = () => {
      const resObj = onReadyStateChange(xhr);
      if (resObj) {resolve(resObj)};
    }

    xhr.send(formData);
  });
}

export {registerUser, loginUserPID, loginUserEmail, deleteUser, searchEvents, 
  searchPeople, getRecommendedEvents, getRecommendedPeople, cancelEvent, 
  getPerson, getPeople, addEvent, getSavedEvents, leaveEvent, joinEvent,
  acceptPerson, rejectPerson, getQuestions, getAnswers, setAnswers, 
  getEmbedding, removePerson, getInteractedPeople, getPoints, setPoints,
  invitePerson, declineInvitation, declineAllInvitations, getInvitedEvents,
  getUsersFutureEvents, getHarmonyAnalysis, getIntroStats, resendPassword,
  giveFeedback};
