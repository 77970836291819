import {deepSetMap} from './helpers.js';

function checkRegistrationForm(formMap, setMessages, setAreFieldsReady, setIsPhotoReady) {
  setMessages(prevMessages => deepSetMap(prevMessages, 'regButton', null));

  const regEmail = formMap.get('regEmail');
  if (!regEmail || !regEmail.includes('@') || !regEmail.endsWith('.com') || 
    regEmail.includes('"') || regEmail.includes("'")) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'regEmail', 
      'Lütfen geçerli bir (.com) email girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'regEmail', null));
  }

  const regPassword = formMap.get('regPassword');
  if (!regPassword || regPassword.length < 6 || 
    regPassword.includes('"') || regPassword.includes("'")) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'regPassword', 
      'Lütfen geçerli bir şifre girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'regPassword', null));
  }

  const regName = formMap.get('regName');
  if (!regName || regName.length <= 2) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'regName', 
      'Lütfen geçerli bir isim girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'regName', null));
  }

  const regSurname = formMap.get('regSurname');
  if (!regSurname || regSurname.length <= 2) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'regSurname', 
      'Lütfen geçerli bir soyisim girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'regSurname', null));
  }

  const regDOB = formMap.get('regDOB');
  if (!regDOB || (new Date(regDOB)).getTime() >
    Date.now() - 18*365*24*60*60*1000) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'regDOB', 
      'Lütfen geçerli doğum tarihi girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'regDOB', null));
  }

  const regPhone = formMap.get('regPhone');
  if (!regPhone || regPhone.length !== 10 || regPhone[0] !== '5') {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'regPhone', 
      'Lütfen geçerli bir telefon (5xxxxxxxxx) girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'regPhone', null));
  }

  const regCity = formMap.get('regCity');
  if (!regCity || regCity.length <= 2) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'regCity', 
      'Lütfen geçerli bir şehir girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'regCity', null));
  }

  const regStatement = formMap.get('regStatement');
  if (!regStatement || regStatement.length <= 10) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'regStatement', 
      'Lütfen geçerli bir ileti girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'regStatement', null));
  }

  const regPhoto = formMap.get('regPhoto');
  if (!regPhoto) {
    setIsPhotoReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'regPhoto', 
      'Lütfen bir fotoğraf ekleyin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'regPhoto', null));
  }

  // Congratz, passed all tests!
  setAreFieldsReady(true);
  setIsPhotoReady(true);
}

function checkLoginForm(formMap, setMessages, setAreFieldsReady) {
  setMessages(prevMessages => deepSetMap(prevMessages, 'logButton', null));

  const logEmail = formMap.get('logEmail');
  if (!logEmail || !logEmail.includes('@') || !logEmail.endsWith('.com')) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'logEmail', 
      'Lütfen geçerli bir (.com) email girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'logEmail', null));
  }

  const logPassword = formMap.get('logPassword');
  if (!logPassword || !logPassword.length) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'logPassword', 
      'Lütfen geçerli bir şifre girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'logPassword', null));
  }

  setAreFieldsReady(true);
}

function checkAddEventForm(formMap, setMessages, setAreFieldsReady, setIsPhotoReady) {
  setMessages(prevMessages => deepSetMap(prevMessages, 'addEventButton', null));

  const addEventTitle = formMap.get('addEventTitle');
  if (!addEventTitle || addEventTitle.length < 10) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventTitle', 
      'Lütfen geçerli bir başlık girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventTitle', null));
  }

  const addEventPhoto = formMap.get('addEventPhoto');
  if (!addEventPhoto) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventPhoto', 
      'Lütfen geçerli bir fotoğraf ekleyin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventPhoto', null));
  }

  const addEventDescription = formMap.get('addEventDescription');
  if (!addEventDescription || addEventDescription.length < 10) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventDescription', 
      'Lütfen geçerli bir açıklama girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventDescription', null));
  }

  const addEventMaxAttendeeCount = formMap.get('addEventMaxAttendeeCount');
  if (!addEventMaxAttendeeCount || addEventMaxAttendeeCount < 2) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventMaxAttendeeCount', 
      'Lütfen geçerli bir maksimum kişi sayısı girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventMaxAttendeeCount', null));
  }

  const addEventTime = formMap.get('addEventTime');
  if (!addEventTime || Date.now() >= (new Date(addEventTime).getTime())) {
    setAreFieldsReady(false);
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventTime', 
      'Lütfen geçerli bir tarih girin.')
    );
    return;
  } else {
    setMessages(prevMessages => deepSetMap(prevMessages, 'addEventTime', null));
  }

  setAreFieldsReady(true);
  setIsPhotoReady(true);
}

function checkSetAnswersForm(formMap, setMessages, setAreFieldsReady, questions) {
  setMessages(prevMessages => deepSetMap(prevMessages, 'setAnswersButton', null));

  for (let questionObj of questions) {
    let qid = questionObj.qid;
    let answer = formMap.get(qid);
    if (!answer || answer.replace(/ /g, '').length < 30) {
      setAreFieldsReady(false);
      setMessages(prevMessages => deepSetMap(prevMessages, qid, 
        'Lütfen daha uzun bir cevap girin.'));
      return;
    } if (!answer || answer.replace(/ /g, '').length > 400) {
      setAreFieldsReady(false);
      setMessages(prevMessages => deepSetMap(prevMessages, qid, 
        'Lütfen daha kısa bir cevap girin.'));
      return;
    }else {
      setMessages(prevMessages => deepSetMap(prevMessages, qid, null));
    }
    setAreFieldsReady(true);
  }
}

export {checkRegistrationForm, checkLoginForm, checkAddEventForm, 
  checkSetAnswersForm};
