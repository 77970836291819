/*global google*/
const { Map } = await google.maps.importLibrary("maps");
const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

class MyMap {
  constructor(divID, lat, lng) {
    if (lat) {
      this.position = {lat: lat, lng: lng};
      this.map = new Map(document.getElementById(divID), {
        zoom: 14,
        center: this.position,
        mapId: "map"
      });

			this.marker = new AdvancedMarkerElement({
				map: this.map,
				position: this.position,
			});
    } else {
      navigator.geolocation.getCurrentPosition(position => {
        this.position = {lat: position.coords.latitude, lng: position.coords.longitude};
        this.map = new Map(document.getElementById(divID), {
          zoom: 12,
          center: this.position,
          mapId: "map"
        });
        this.marker = new AdvancedMarkerElement({
          map: this.map,
          position: this.position,
        });
        google.maps.event.addListener(this.map, 'click', evnt => {
          this.position = {lat: evnt.latLng.lat(), lng: evnt.latLng.lng()};
          this.marker.map = null;
          delete this.marker;
          this.marker = new AdvancedMarkerElement({
            map: this.map,
            position: this.position,
          });
        });
      }, () => {}, {maximumAge:10000, timeout:5000, enableHighAccuracy: true});
    }
  }

  getPosition() {
    return this.position;
  }
}

export default MyMap;
