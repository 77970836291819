import Navigation from './Navigation';
import {url} from './env';
import './Header.css';

function Header({isLoggedIn, userData, currentView, setCurrentView}) {
  return (
    <div className='header'>
      <div className='logo'>
        <p>bsocial.today</p>
        <img src={url + 'client/public/logo.svg'} />
      </div>
      <Navigation 
        isLoggedIn={isLoggedIn} 
        userData={userData}
        currentView={currentView} 
        setCurrentView={setCurrentView} />
    </div>
  );
}

export default Header;
