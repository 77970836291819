import {useState, useEffect} from 'react';
import PeopleSearchForm from './PeopleSearchForm';
import Person from './Person';
import {getRecommendedPeople, getUsersFutureEvents} from './requests';
import './PeopleView.css';

function PeopleView({userData}) {
  const [sPeoplesData, setSPeoplesData] = useState([]);
  const [rPeoplesData, setRPeoplesData] = useState([]);
  const [usersFutureEvents, setUsersFutureEvents] = useState([]);

  function mount() {
    getRecommendedPeople(userData.pid, userData.password)
      .then(recommendedPeople => setRPeoplesData(recommendedPeople))
      .catch(e => alert(e));
    getUsersFutureEvents(userData.pid, userData.password)
      .then(events => setUsersFutureEvents(events))
      .catch(e => alert(e));
  }

  useEffect(() => {mount()}, []);

  return (
    <div id='peopleView' >
      <div className='peopleViewTop'>
        <PeopleSearchForm userData={userData} setSPeoplesData={setSPeoplesData} />
        <span></span>
      </div>
      <hr /><hr /><hr />
      {sPeoplesData.length ? <h3>Arama Sonuçları</h3>:null}
      <div className='sPeople'>
        {sPeoplesData.map((personData, i) => <Person key={i} personData={personData}
          setPeoplesData={setSPeoplesData} userData={userData} usersFutureEvents={usersFutureEvents} />)
        }
      </div>
      {sPeoplesData.length ? <><hr /><hr /></>:null}
      <h3>Öneriler</h3>
      <div className='rPeople'>
        {rPeoplesData.map((personData, i) => <Person key={i} personData={personData}
          setPeoplesData={setRPeoplesData} userData={userData} usersFutureEvents={usersFutureEvents} />)
        }
        {rPeoplesData.length === 0 &&
          <p>Yakınında kullanıcı bulamadık... Siteyi paylaşmak ister misin?</p>
        }
      </div>
    </div>
  );
}

export default PeopleView;

