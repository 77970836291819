import './Footer.css';
import {url} from './env';

function Footer() {
  return (
    <div className='footer'>
      <img src={url + 'client/public/logo.svg'} />
    </div>
  );
}

export default Footer;
