import SetPointsForm from './SetPointsForm';

function SetPointsView({userData, setUserData, setCurrentView}) {
  return (
    <SetPointsForm userData={userData} setUserData={setUserData} 
      setCurrentView={setCurrentView} />
  );

}

export default SetPointsView;
