import {useState} from 'react';
import {searchPeople} from './requests';
import {InputText, InputButton} from './Inputs';
import {deepSetMap} from './helpers';

function PeopleSearchForm({userData, setSPeoplesData}) {
  const [formMap, setFormMap] = useState(new Map());

  function handleQueryChange({target}) {
    setFormMap(prevFormMap => deepSetMap(prevFormMap, target.id, target.value));
  }

  async function handleSearchClick(e) {
    e.preventDefault();
    formMap.set('pid', userData.pid);
    formMap.set('password', userData.password);
    const searchResults = await searchPeople(formMap);
    setSPeoplesData(searchResults);
  }

  return (
    <>
      <InputText id='peopleSearchQuery' onChange={handleQueryChange} 
        value={formMap.get('peopleSearchQuery') || ''} placeholder='Ara' />
      <InputButton id='peopleSearchButton' onClick={handleSearchClick} value={'Kişi Ara'} />
    </>
  );
}

export default PeopleSearchForm;
