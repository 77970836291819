import {s3Prefix} from './env';
import {date2DateString, date2DatetimeString} from './helpers';
import {getCharacteristicScore, HarmonyTable} from './harmony';
import {getInvitedEvents, declineAllInvitations} from './requests';
import {InputButton} from './Inputs';
import './User.css';

function User({userData, setUserData, invitedEventsData, setInvitedEventsData, 
  userAnswers}) {
  async function handleShowInvitedEventsClick(e) {
    e.preventDefault();
    if (invitedEventsData?.length) {
      setInvitedEventsData(null);
    } else {
      const eventsData = await getInvitedEvents(userData.pid, userData.password);
      setInvitedEventsData(eventsData);
    }
  }

  function handleDeclineAllInvitationsClick(e) {
    e.preventDefault();
    declineAllInvitations(userData.pid, userData.password);
    setInvitedEventsData([]);
    setUserData(prevUserData => {return {...prevUserData, invitedevents: []}});
  }

  return (
    <>
      <div className='user'>
        <img src={s3Prefix + userData.urls[0]} alt='User' />
        <h3>{userData.name} {userData.surname}</h3>
        <p>{date2DateString(new Date(parseInt(userData.dob)))}</p>
        <p>{userData.city}, {userData.country}</p>
        <p>{userData.phone}</p>
        <p>{userData.email}</p>
        <p>Katıldığın tarih: {date2DatetimeString(new Date(parseInt(userData.time)))}</p>
        <p>Katıldığın etkinlik sayısı: {userData.acceptedcount}</p>
        <p>Davet edildiğin etkinlik sayısı: {userData.invitedcount}</p>
        <p>Son cevap verdiğin zaman: {parseInt(userData.answertime) ?
          date2DatetimeString(new Date(parseInt(userData.answertime))):'?'}</p>
        <p>Son algoritma güncellenen zaman: {parseInt(userData.embeddingtime) ?
          date2DatetimeString(new Date(parseInt(userData.embeddingtime))):'?'}</p>
        <br />
        <p>{userData.statement}</p>
        <br />
        <p><b>Ortalama puanın</b> (sana verilen puanların ortalaması): 
          <b> {userData.avgpoint?.toFixed(1) || '?'}</b>
        </p>
        <br />
        {
          userAnswers.map((answer, i) => <p key={'' + i}><b>Soru {i + 1}</b>: {answer.answer}</p>)
        }
        <br />
        <p><b>Karakteristik Puanın </b> 
          (verdiğin cevaplarla yapılan analizlerde ne kadar güçlü bir karakterin olduğunun göstergesi): 
          <b> {getCharacteristicScore(userData.embedding)}</b>
        </p>
        <p><b>Uyum tablosu</b> (sorulara verilen cevapların birbirleri arasındaki uyumu):</p>
        <HarmonyTable userEmbedding={userData.embedding} otherEmbedding={userData.embedding} 
          isUser={true} />
        <InputButton id='showInvitedEventsButton' onClick={handleShowInvitedEventsClick} 
          value={'Davet Edildiğin (' + userData.invitedevents.length + ')'} />
        <InputButton id='declineAllInvitationsButton' onClick={handleDeclineAllInvitationsClick} 
          value='Tüm Davetleri Reddet' />
      </div>
      <hr /><hr /><hr />
    </>
  );
}

export default User;
