import {useState, useEffect} from 'react';
import {InputTextArea, InputButton} from './Inputs';
import {getQuestions, getAnswers, setAnswers} from './requests';
import {checkSetAnswersForm} from './checkForm';
import {deepSetMap} from './helpers';
import './SetAnswersForm.css';

function SetAnswersForm({userData, setUserData, setCurrentView, userAnswers, 
  setUserAnswers}) {
  const [questions, setQuestions] = useState([]);
  const [formMap, setFormMap] = useState(new Map());
  const [messages, setMessages] = useState(new Map());
  const [areFieldsReady, setAreFieldsReady] = useState(false);

  async function mount() {
    const results = await getQuestions(userData.pid, userData.password);
    setQuestions(results);
    const formMap = new Map();
    for (let answer of userAnswers) {
      formMap.set(answer.qid, answer.answer);
    }
    setFormMap(formMap);
  }

  useEffect(() => {mount()}, []);

  function handleChange({target}) {
    setFormMap(prevFormMap => {
      const formMap = 
        deepSetMap(prevFormMap, parseInt(target.id.split('-')[1]), target.value);
      checkSetAnswersForm(formMap, setMessages, setAreFieldsReady, questions);
      return formMap;
    });
  }

  function handleSetAnswersClick(e) {
    e.preventDefault();
    if (!areFieldsReady) {
      setMessages(prevMessages => deepSetMap(prevMessages, 'setAnswersButton', 
          'Lütfen girdileri kontrol edin.'));
      return;
    }
    const newAnswers = [];
    for (let key of formMap.keys()) {
      newAnswers.push({qid: key, answer: formMap.get(key)});
    }
    setUserAnswers(newAnswers);
    formMap.set('pid', userData.pid);
    formMap.set('password', userData.password);
    setAnswers(formMap);
    setUserData(prevUserData => {return {...prevUserData, answertime: Date.now()}});
    setCurrentView('UserView');
  }

  return (
    <div className='answerQuestionsForm'>
      <h3 className='h3title'>Yapay Zeka Soruları</h3>
      <p>
        Soruları cevapladıktan sonra cevapların analiz edilmesi 5-10 saniye alabilir. 
        Bu yüzden kendi sayfana bakarken sayfayı yenileyip algoritma güncellenen zamanın 
        yenilenmesini bekle. Bundan sonra analiz sonuçlarını görebilirsin.
      </p>
      <br />
      {questions.map(({qid, question}) => 
        {return (
          <div key={'question-' + qid}>
            <h3>{question}</h3>
            <InputTextArea id={'question-' + qid} onChange={handleChange} 
              value={formMap.get(qid) || ''} message={messages.get(qid)} />
          </div>
        )}
      )}
      <br />
      <InputButton id='setAnswersButton' onClick={handleSetAnswersClick} 
        value='Kaydet' message={messages.get('setAnswersButton')} />
    </div>
  );
}

export default SetAnswersForm;
