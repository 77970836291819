import {useState, useEffect} from 'react';
import {getInteractedPeople, getPoints, setPoints} from './requests';
import Person from './Person';
import {InputButton} from './Inputs';

function SetPointsForm({userData, setUserData, setCurrentView}) {
  const [formMap, setFormMap] = useState(new Map());
  const [interactedPeoplesData, setInteractedPeoplesData] = useState([]);

  async function mount() {
    const res = await Promise.all([getInteractedPeople(userData.pid, userData.password), 
      getPoints(userData.pid, userData.password)]);
    setInteractedPeoplesData(res[0]);

    const formMap = new Map();
    for (let personData of res[1]) {
      formMap.set(personData.pid2, personData.point);
    }
    setFormMap(formMap);
  }

  useEffect(() => {mount()}, []);

  function handleSetPointsClick(e) {
    e.preventDefault();
    formMap.set('pid', userData.pid);
    formMap.set('password', userData.password);
    setPoints(formMap);
    setCurrentView('UserView');
  }

  return (
    <div className='setPointsForm'>
      {
        interactedPeoplesData.map(personData => 
          <Person key={'person-' + personData.pid} personData={personData} 
            userData={userData} point={formMap.get(personData.pid)} 
            setPointFormMap={setFormMap} />
        )
      }
      <InputButton id='setPointsButton' onClick={handleSetPointsClick} 
        value='Kaydet' />
    </div>
  );
}

export default SetPointsForm;
