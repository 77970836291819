import './Inputs.css';

function InputText({id, onChange, value, message, placeholder}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <input id={id} type='text' onChange={onChange} value={value} autoComplete='on' 
        placeholder={placeholder} />
    </>
  );
}

function InputTextArea({id, onChange, value, message}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <textarea id={id} onChange={onChange} value={value} autoComplete='on' />
    </>
  );
}

function InputNumber({id, onChange, value, message}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <input id={id} type='number' onChange={onChange} value={value} autoComplete='on' />
    </>
  );
}

function InputPhone({id, onChange, value, message}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <input id={id} type='tel' onChange={onChange} value={value} autoComplete='on' />
    </>
  );
}

function InputEmail({id, onChange, value, message}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <input id={id} type='email' onChange={onChange} value={value} autoComplete='on' />
    </>
  );
}

function InputPassword({id, onChange, value, message}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <input id={id} type='password' onChange={onChange} value={value} />
    </>
  );
}

function InputButton({id, onClick, value, message}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <a className='button' id={id} href='button' onClick={onClick}>{value}</a>
    </>
  );
}

function InputSelect({id, options, onChange, value}) {
  return (
    <select id={id} onChange={onChange} value={value} autoComplete='on' >
      {options.map((option, i) => 
        <option key={i} value={option.toLowerCase()}>{option}</option>)}
    </select>
  );
}

function InputDate({id, onChange, value, message}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <input type='date' id={id} onChange={onChange} value={value} />
    </>
  );
}

function InputDatetime({id, onChange, value, message}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <input type='datetime-local' id={id} onChange={onChange} value={value} />
    </>
  );
}

function InputPhoto({id, onChange, value, message}) {
  return (
    <>
      {message ? <p>{message}</p>:null}
      <input type='file' accept="image/*" id={id} onChange={onChange} />
      {value ? <p>Seçilen dosya: {value}</p>:null}
    </>
  );
}

export {InputText, InputNumber, InputEmail, InputPassword, InputButton, InputSelect, 
  InputDate, InputPhoto, InputPhone, InputDatetime, InputTextArea};
