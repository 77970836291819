import RegistrationForm from './RegistrationForm';

function RegistrationView({setIsLoggedIn, setUserData, setCurrentView, coords}) {
  return (
    <RegistrationForm 
      setIsLoggedIn={setIsLoggedIn}
      setUserData={setUserData}
      setCurrentView={setCurrentView} 
      coords={coords}
    />
  );
}

export default RegistrationView;
