import {InputButton} from './Inputs';
import {logOut} from './helpers';
import {deleteUser} from './requests';

import './SettingsView.css';

function SettingsView({userData, setIsLoggedIn, setUserData, setCurrentView,
  setUserAnswers}) {

  function handleAnswerQuestionsClick(e) {
    e.preventDefault();
    setCurrentView('SetAnswersView');
  }

  function handleSetPointsClick(e) {
    e.preventDefault();
    setCurrentView('SetPointsView');
  }

  function handleGiveFeedbackClick(e) {
    e.preventDefault();
    setCurrentView('GiveFeedbackView');
  }

  function handleLogoutClick(e) {
    e.preventDefault();
    logOut(setIsLoggedIn, setUserData, setCurrentView, setUserAnswers);
  }

  function handleDeleteUserClick(e) {
    e.preventDefault();
    deleteUser(userData.pid, userData.password);
    logOut(setIsLoggedIn, setUserData, setCurrentView);
  }

  return (
    <div className='settingsView'>
      <InputButton id='answerQuestionsButton' onClick={handleAnswerQuestionsClick} value='Soru Cevapla' />
      <InputButton id='setPointsButton' onClick={handleSetPointsClick} value='Puan Ver' />
      <InputButton id='GiveFeedbackButton' onClick={handleGiveFeedbackClick} value='Öneride Bulun' />
      <InputButton id='logoutButton' onClick={handleLogoutClick} value='Çıkış Yap' />
      <InputButton id='deleteUserButton' onClick={handleDeleteUserClick} value='Hesabımı Sil' />
    </div>
  );
}

export default SettingsView;
