// React
import {useState} from 'react';

// Components
import {InputButton, InputText, InputEmail, InputPassword, InputSelect, 
  InputDate, InputPhoto, InputPhone, InputTextArea} from './Inputs';
import Terms from './Terms';

// Functions
import {registerUser} from './requests';
import {checkRegistrationForm} from './checkForm';
import {deepSetMap} from './helpers.js';

// Styles
import './RegistrationForm.css';

// Form
function RegistrationForm({setIsLoggedIn, setUserData, setCurrentView, coords}) {
  const [formMap, setFormMap] = useState(new Map([['regGender', 'erkek']]));
  const [messages, setMessages] = useState(new Map());
  const [areFieldsReady, setAreFieldsReady] = useState(false);
  const [isPhotoReady, setIsPhotoReady] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [readTerms, setReadTerms] = useState(false);
  const options = ['Erkek', 'Kadın', 'Diğer'];

  function handleChange({target}) {
    setFormMap(prevFormMap => {
      const formMap = deepSetMap(prevFormMap, target.id, target.value)
      checkRegistrationForm(formMap, setMessages, setAreFieldsReady, setIsPhotoReady);
      return formMap;
    });
  }

  function handleChangePhoto ({target}) {
    setFormMap(prevFormMap => {
      const formMap = deepSetMap(prevFormMap, target.id, target.files[0])
      checkRegistrationForm(formMap, setMessages, setAreFieldsReady, setIsPhotoReady);
      return formMap;
    });
  }

  function handleShowTermsClick(e) {
    e.preventDefault();
    setShowTerms(prevShowTerms => !prevShowTerms);
    setReadTerms(true);
  }

  function handleRegisterClick(e) {
    e.preventDefault();
    if(!areFieldsReady || !isPhotoReady) {
      setMessages(prevMessages => deepSetMap(prevMessages, 'regButton', 
          'Lütfen girdileri kontrol edin.'));
      return;
    }
    if(!readTerms) {
      setMessages(prevMessages => deepSetMap(prevMessages, 'regButton', 
          'Lütfen koşulları okuyun.'));
      return;
    }

    formMap.set('lat', coords.lat);
    formMap.set('lng', coords.lng);
    formMap.set('regCountry', 'Türkiye');
    registerUser(formMap)
      .then(resObj => {
        localStorage.isLoggedIn = 'true';
        localStorage.pid = resObj.pid;
        localStorage.password = resObj.password;
        setIsLoggedIn(true);
        resObj.embedding = eval(resObj.embedding);
        setUserData(resObj);
        setCurrentView('EventsView');
      })
      .catch(error => alert(error));
  }

  return (
    <div className='registrationForm'>
      <h3>Kayıt Ol</h3>
      <p>Email</p>
      <InputEmail id='regEmail' onChange={handleChange} 
        value={formMap.get('regEmail') || ''} message={messages.get('regEmail')} />

      <p>Şifre</p>
      <InputPassword id='regPassword' onChange={handleChange} 
        value={formMap.get('regPassword') || ''} message={messages.get('regPassword')} />

      <p>İsim</p>
      <InputText id='regName' onChange={handleChange} 
        value={formMap.get('regName') || ''} message={messages.get('regName')} />

      <p>Soyisim</p>
      <InputText id='regSurname' onChange={handleChange} 
        value={formMap.get('regSurname') || ''} message={messages.get('regSurname')} />

      <p>Cinsiyet</p>
      <InputSelect id='regGender' options={options} onChange={handleChange} 
        value={formMap.get('regGender') || ''} message={messages.get('regGender')} />

      <p>Doğum Tarihi</p>
      <InputDate id='regDOB' onChange={handleChange} 
        value={formMap.get('regDOB') || ''} message={messages.get('regDOB')} />

      <p>Telefon</p>
      <InputPhone id='regPhone' onChange={handleChange} 
        value={formMap.get('regPhone') || ''} message={messages.get('regPhone')} />

      <p>Memleket</p>
      <InputText id='regCity' onChange={handleChange} 
        value={formMap.get('regCity') || ''} message={messages.get('regCity')} />

      <p>Kişisel İleti</p>
      <InputTextArea id='regStatement' onChange={handleChange} 
        value={formMap.get('regStatement') || ''} message={messages.get('regStatement')} />

      <p>Fotoğraf</p>
      <InputPhoto id='regPhoto' onChange={handleChangePhoto} 
        message={messages.get('regPhoto')} />
    
      {showTerms && <Terms />}

      <div>
        <InputButton id='regShowTermsButton' value='Koşullar' 
          onClick={handleShowTermsClick} />
        <InputButton id='regButton' value='Kayıt ol' 
          onClick={handleRegisterClick} message={messages.get('regButton')} />
      </div>
    </div>
  );
}

export default RegistrationForm;
